import { RootState } from "..";

export const fromTiktok = (state: RootState) => state.tiktokHashtags;

export const tiktokTrendingHashtagsSelector = (state: RootState) =>
  fromTiktok(state).tiktokTrendingHashtags;
export const fetchingTiktokTrendingHashtagsSelector = (state: RootState) =>
  fromTiktok(state).tiktokFetchingTrendingHashtags;
export const fetchedTiktokTrendingHashtagsSelector = (state: RootState) =>
  fromTiktok(state).tiktokFetchedTrendingHashtags;
export const fetchedTiktokTrendingHashtagsSelectorFaild = (state: RootState) =>
  fromTiktok(state).tiktokFetchedTrendingHashtagsFail;

export const tiktokTrendingHashtagsFiltersSelector = (state: RootState) =>
  fromTiktok(state).tiktokTrendingHashtagsFilters;
export const fetchingTiktokTrendingHashtagsFiltersSelector = (state: RootState) =>
  fromTiktok(state).tiktokFetchingTrendingHashtagsFilters;
export const fetchedTiktokTrendingHashtagsFiltersSelector = (state: RootState) =>
  fromTiktok(state).tiktokFetchedTrendingHashtagsFilters;
export const fetchedTiktokTrendingHashtagsFiltersSelectorFaild = (state: RootState) =>
  fromTiktok(state).tiktokFetchedTrendingHashtagsFiltersFail;

export const fetchingTiktokTrendingHashtagVideosSelector = (state: RootState) =>
  fromTiktok(state).tiktokFetchingTrendingHashtagVideos;
