import { createReducer, ActionType } from "typesafe-actions";

import { ITiktokState } from "./types";
import {
  getTrendingHashtagsFiltersSuccessAction,
  getTrendingHashtagsSuccessAction,
  getTrendingHashtagVideosSuccessAction,
} from "./actions";
import {
  TIKTOK_GET_TRENDING_HASHTAGS,
  TIKTOK_GET_TRENDING_HASHTAGS_SUCCESS,
  TIKTOK_GET_TRENDING_HASHTAGS_FAILURE,
  TIKTOK_GET_TRENDING_HASHTAGS_FILTERS,
  TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_SUCCESS,
  TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_FAILURE,
  TIKTOK_GET_TRENDING_HASHTAG_VIDEOS_SUCCESS,
  TIKTOK_GET_TRENDING_HASHTAG_VIDEOS,
  TIKTOK_GET_TRENDING_HASHTAG_VIDEOS_FAILURE,
} from "./actionTypes";

const initialState = {
  tiktokTrendingHashtags: [],
  tiktokFetchingTrendingHashtags: false,
  tiktokFetchingTrendingHashtagVideos: false,
  tiktokFetchedTrendingHashtags: false,
  tiktokFetchedTrendingHashtagsFail: false,

  tiktokTrendingHashtagsFilters: {
    industries: [],
    countries: [],
    dateRangeOptions: [],
  },
  tiktokFetchingTrendingHashtagsFilters: false,
  tiktokFetchedTrendingHashtagsFilters: false,
  tiktokFetchedTrendingHashtagsFiltersFail: false,
};

export const tiktokHashtagsReducer = createReducer<ITiktokState>(initialState, {
  [TIKTOK_GET_TRENDING_HASHTAGS]: (state: ITiktokState) => ({
    ...state,
    tiktokFetchingTrendingHashtags: true,
    tiktokFetchedTrendingHashtags: false,
    tiktokFetchedTrendingHashtagsFail: false,
  }),
  [TIKTOK_GET_TRENDING_HASHTAGS_SUCCESS]: (
    state: ITiktokState,
    action: ActionType<typeof getTrendingHashtagsSuccessAction>,
  ) => ({
    ...state,
    tiktokTrendingHashtags: action.payload.data,
    tiktokFetchingTrendingHashtags: false,
    tiktokFetchedTrendingHashtags: true,
    tiktokFetchedTrendingHashtagsFail: false,
  }),
  [TIKTOK_GET_TRENDING_HASHTAG_VIDEOS]: (state: ITiktokState) => ({
    ...state,
    tiktokFetchingTrendingHashtagVideos: true,
  }),
  [TIKTOK_GET_TRENDING_HASHTAG_VIDEOS_SUCCESS]: (
    state: ITiktokState,
    action: ActionType<typeof getTrendingHashtagVideosSuccessAction>,
  ) => {
    return {
      ...state,
      tiktokTrendingHashtags: state.tiktokTrendingHashtags.map(hashtag => ({
        ...hashtag,
        videos: action.payload.find(item => item.id === hashtag.id)?.items || [],
      })),
      tiktokFetchingTrendingHashtagVideos: false,
    };
  },
  [TIKTOK_GET_TRENDING_HASHTAG_VIDEOS_FAILURE]: (state: ITiktokState) => ({
    ...state,
    tiktokFetchingTrendingHashtagVideos: false,
  }),
  [TIKTOK_GET_TRENDING_HASHTAGS_FAILURE]: (state: ITiktokState) => ({
    ...state,
    tiktokTrendingHashtags: [],
    tiktokFetchingTrendingHashtags: false,
    tiktokFetchedTrendingHashtags: false,
    tiktokFetchedTrendingHashtagsFail: true,
  }),
  [TIKTOK_GET_TRENDING_HASHTAGS_FILTERS]: (state: ITiktokState) => ({
    ...state,
    tiktokFetchingTrendingHashtagsFilters: true,
    tiktokFetchedTrendingHashtagsFilters: false,
    tiktokFetchedTrendingHashtagsFiltersFail: false,
  }),
  [TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_SUCCESS]: (
    state: ITiktokState,
    action: ActionType<typeof getTrendingHashtagsFiltersSuccessAction>,
  ) => ({
    ...state,
    tiktokTrendingHashtagsFilters: action.payload,
    tiktokFetchingTrendingHashtagsFilters: false,
    tiktokFetchedTrendingHashtagsFilters: true,
    tiktokFetchedTrendingHashtagsFiltersFail: false,
  }),
  [TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_FAILURE]: (state: ITiktokState) => ({
    ...state,
    tiktokFetchingTrendingHashtagsFilters: false,
    tiktokFetchedTrendingHashtagsFilters: false,
    tiktokFetchedTrendingHashtagsFiltersFail: true,
  }),
});
