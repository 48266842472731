import classNames from "classnames";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card } from "@bbdevcrew/bb_ui_kit_fe";
import BrandReply from "./brandReply/BrandReply";
import MessageTags from "./messageTags/MessageTags";
import CommentCardBody from "./cardBody/CommentCardBody";
import CustomTagsList from "./customTags/CustomTagsList";
import BrandBastionTags from "./brandBastionTags/BBTags";
import CommentTagsList from "./commentTags/CommentTagsList";
import AiHighlightTags from "./aiHighlightTags/AiHighlightTags";
import CustomTagsDropdown from "./customTags/CustomTagsDropdown";
import CommentCardHeader from "./cardHeader/CommentCardHeader";
import { TrustpilotStars } from "../MessageList/ReviewMessage";
import { GoogleBusinessStars } from "../MessageList/ReviewMessage";

import { canManageBBTagsSelector } from "@store/me/selectors";
import { deleteCustomTagFromCommentAction } from "@store/customTags/actions";
import { deleteCommentTagAction, postCommentTagAction } from "@store/commentTags/actions";

import s from "./CommentCard.module.less";

import { isPrivateConversation } from "@utils/comments";

import { ICommentCardProps } from "./CommentCard.type";
import { ICommentTag } from "@store/comments/types";

const CommentCard: FC<ICommentCardProps> = React.memo(
  ({
    comment,
    isParent,
    commentIndex,
    maxLengthComment,
    previewMode,
    withFooter,
    onReplyClick,
    onTranslateClick,
  }) => {
    const dispatch = useDispatch();

    const commentReplied = comment.reply.is_sent && !!comment.reply.replied_as;
    const replyNotEmpty = !!comment.reply.raw_message || !!comment.reply.message;
    const isTrustpilotPlatform = comment.platform_type.id === "trustpilot";
    const isGoogleBusinessPlatform = comment.platform_type.id === "google_business";

    const canManageBBTag = useSelector(canManageBBTagsSelector);

    const handleDeleteCustomTag = (tagId?: string) => {
      tagId &&
        dispatch(
          deleteCustomTagFromCommentAction({
            id: tagId,
            comment_id: comment.id,
          }),
        );
    };

    const handleDeleteBBTag = (tagId: string) => {
      dispatch(deleteCommentTagAction({ tagId, commentId: comment.id }));
    };

    const handleAddBBTag = (tag: ICommentTag) => {
      dispatch(postCommentTagAction({ tagId: tag.id, commentId: comment.id }));
    };

    const isPrivateMessage =
      comment.post_placement && isPrivateConversation(comment.post_placement);

    return (
      <div
        data-cy="comment-card"
        className={classNames(s.bbCommentCard, s[`bbCommentCardSentiment-${comment.sentiment}`], {
          [s.bbCommentCardInfo]: !!comment.sentiment,
        })}
      >
        <Card>
          <CommentCardHeader
            comment={comment}
            onReplyClick={onReplyClick}
            previewMode={previewMode}
          />
          {isTrustpilotPlatform && <TrustpilotStars stars={comment.recommendation_type} />}
          {isGoogleBusinessPlatform && <GoogleBusinessStars stars={comment.recommendation_type} />}
          <CommentCardBody
            comment={comment}
            isParent={isParent}
            onTranslateClick={onTranslateClick}
            maxLengthComment={maxLengthComment || 900}
            onReplyClick={onReplyClick}
          />

          {!previewMode && (
            <div className={s.bbCommentCardTags}>
              <CommentTagsList
                commentId={comment.id}
                tags={comment.tags || []}
                premiumTag={comment.tooltips?.premium}
                className={s.bbCommentCardTagsList}
              />

              <AiHighlightTags tags={comment.ai_tags} />

              <CustomTagsList
                customTagsAutomated={comment.custom_tags_by_automation}
                customTagsByUser={comment.custom_tags_by_user}
                onDeleteTag={handleDeleteCustomTag}
              />

              <CustomTagsDropdown
                commentId={comment.id}
                customTagsByUser={comment.custom_tags_by_user || []}
                customTagsAutomated={comment.custom_tags_by_automation || []}
                hasTags={!!(comment.tags?.length || comment.ai_tags?.length)}
              />

              {canManageBBTag && (
                <BrandBastionTags
                  tags={comment.tags || []}
                  onAddTag={handleAddBBTag}
                  onDeleteTag={handleDeleteBBTag}
                />
              )}

              {!!comment.message_tags?.count && <MessageTags tags={comment.message_tags} />}
            </div>
          )}

          {!isPrivateMessage && commentReplied && replyNotEmpty && (
            <BrandReply comment={comment} onReplyClick={onReplyClick} />
          )}

          {withFooter && (
            <div className={s.bbCommentCardFooter} data-cy="comment-card-footer">
              {withFooter(comment, commentIndex)}
            </div>
          )}
        </Card>
      </div>
    );
  },
);

CommentCard.displayName = "CommentCard";

export default CommentCard;
