import { action } from "typesafe-actions";

import {
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_CSV,
  GET_DASHBOARD_CSV_FAILURE,
  GET_DASHBOARD_FOLLOWERS,
  GET_DASHBOARD_FOLLOWERS_SUCCESS,
  GET_DASHBOARD_FOLLOWERS_FAILURE,
  GET_DASHBOARD_IMPRESSIONS,
  GET_DASHBOARD_IMPRESSIONS_SUCCESS,
  GET_DASHBOARD_IMPRESSIONS_FAILURE,
  GET_DASHBOARD_ENGAGEMENTS_FAILURE,
  GET_DASHBOARD_ENGAGEMENTS,
  GET_DASHBOARD_ENGAGEMENTS_SUCCESS,
} from "./actionTypes";

import {
  AgentPerformanceReportType,
  IDashboard,
  IDashboardPayload,
  IDashboardFollowers,
  IDashboardImpressions,
  IDashboardEngagements,
} from "./types";
import { IFilters } from "../filters/types";

export const postDashboardAction = (payload: IDashboardPayload) => action(GET_DASHBOARD, payload);
export const postDashboardActionSuccess = (data: IDashboard) => action(GET_DASHBOARD_SUCCESS, data);
export const postDashboardActionFailure = () => action(GET_DASHBOARD_FAILURE);

export const getDashboardCSVAction = (filters: IFilters, report_type: AgentPerformanceReportType) =>
  action(GET_DASHBOARD_CSV, { filters, report_type });

export const getDashboardCSVActionFailure = () => action(GET_DASHBOARD_CSV_FAILURE);

export const getDashboardFollowersAction = (payload: IFilters) =>
  action(GET_DASHBOARD_FOLLOWERS, payload);
export const getDashboardFollowersActionSuccess = (data: IDashboardFollowers) =>
  action(GET_DASHBOARD_FOLLOWERS_SUCCESS, data);
export const getDashboardFollowersActionFailure = () => action(GET_DASHBOARD_FOLLOWERS_FAILURE);

export const getDashboardImpressionsAction = (payload: IFilters) =>
  action(GET_DASHBOARD_IMPRESSIONS, payload);
export const getDashboardImpressionsActionSuccess = (data: IDashboardImpressions) =>
  action(GET_DASHBOARD_IMPRESSIONS_SUCCESS, data);
export const getDashboardImpressionsActionFailure = () => action(GET_DASHBOARD_IMPRESSIONS_FAILURE);

export const getDashboardEngagementsAction = (payload: IFilters) =>
  action(GET_DASHBOARD_ENGAGEMENTS, payload);
export const getDashboardEngagementsActionSuccess = (data: IDashboardEngagements) =>
  action(GET_DASHBOARD_ENGAGEMENTS_SUCCESS, data);
export const getDashboardEngagementsActionFailure = () => action(GET_DASHBOARD_ENGAGEMENTS_FAILURE);
