import React from "react";
import classNames from "classnames/bind";

import { PerformanceCardPill } from "@components/insights/_common/performanceCard/Pill";
import { Col } from "antd";

import s from "./ProfilesTable.module.less";

import { formatter } from "@components/_common/CardStat/helpers";
import { ProfilesTablePrefixProps } from "./ProfilesTable.types";

export const ProfilesTablePrefix = ({ value, type }: ProfilesTablePrefixProps) => {
  return (
    <div className={classNames(s.bbProfilesTableRow, s.bbProfilesTablePrefix)}>
      {type === "followers" && (
        <>
          <Col span={5}>{value?.asset_name}</Col>
          <Col span={5}>
            {value?.followers_count != null ? formatter(value?.followers_count) : ""}
          </Col>
          <Col span={7}>{value?.percentage != null ? `${value.percentage}%` : ""}</Col>
          <Col span={7}>
            {!!value?.growth && <PerformanceCardPill percentageValue={Number(value.growth)} />}
          </Col>
        </>
      )}
      {type === "impressions-platform" && (
        <>
          <Col span={6}>{value?.name}</Col>
          <Col className={s.bbPrefixColumn} span={7}>
            {value?.total_impressions != null ? formatter(value?.total_impressions) : ""}
            {!!value?.total_impression_growth && (
              <PerformanceCardPill percentageValue={Number(value.total_impression_growth)} />
            )}
          </Col>
          <Col className={s.bbPrefixColumn} span={6}>
            {value?.organic_impressions != null ? formatter(value?.organic_impressions) : ""}
            {!!value?.organic_impression_growth && (
              <PerformanceCardPill percentageValue={Number(value.organic_impression_growth)} />
            )}
          </Col>
          <Col className={s.bbPrefixColumn} span={5}>
            {value?.paid_impressions != null ? formatter(value?.paid_impressions) : ""}
            {!!value?.paid_impression_growth && (
              <PerformanceCardPill percentageValue={Number(value.paid_impression_growth)} />
            )}
          </Col>
        </>
      )}
      {type === "impressions-profile" && (
        <>
          <Col span={20}>{value?.asset_name}</Col>
          <Col span={4} className={s.bbImpressionColumn}>
            {value?.total_impressions != null ? formatter(value?.total_impressions) : ""}
          </Col>
        </>
      )}
      {type === "engagements" && (
        <>
          <Col span={8}>{value?.asset_name}</Col>
          <Col className={s.bbPrefixColumn} span={7}>
            {value?.er_impressions != null ? formatter(value?.er_impressions) : ""}
            {!!value?.er_impressions_growth && (
              <PerformanceCardPill percentageValue={Number(value.er_impressions_growth)} />
            )}
          </Col>
          <Col className={s.bbPrefixColumn} span={9}>
            {value?.engagements != null ? formatter(value?.engagements) : ""}
            {!!value?.engagements_growth && (
              <PerformanceCardPill percentageValue={Number(value.engagements_growth)} />
            )}
          </Col>
        </>
      )}
    </div>
  );
};
