import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import React, { FC, useCallback, useState, MouseEvent } from "react";

import Filters from "../../_common/AppFilter/Filters";
import { Select as AntSelect, Typography } from "antd";
import { Modal, Button, SelectOld } from "@bbdevcrew/bb_ui_kit_fe";

import { clientDataSelector, meSelector } from "@store/me/selectors";
import { deleteCompareProject } from "@store/compareProjects/actions";

import s from "./CompareHeader.module.less";

import { ICompareHeaderProps } from "./Compare.type";
import { RangeValue } from "rc-picker/lib/interface";
import { ICompareProject } from "@store/compareProjects/types";
import { IDynamicFilter } from "../../_common/AppFilter/Filters.type";
import { IFilterRangeProps } from "../../_common/AppFilter/filters/FilterRange/FilterRange.type";
import { getDefaultFiltersConfig } from "../../_common/AppFilter/config";

import { EditIcon, PlusIcon, RemoveIcon } from "@assets/index";

const CompareHeader: FC<ICompareHeaderProps> = ({
  onFilter,
  filterData,
  onProjectChange,
  selectedProjectId,
  onNewProjectCreate,
  onEditProjectClick,
  compareProjectOptions,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const me = useSelector(meSelector);
  const clientData = useSelector(clientDataSelector);

  const [projectToRemove, setProjectToRemove] = useState<ICompareProject | undefined>();

  const selectedProject = compareProjectOptions.find(project => project.id === selectedProjectId);
  const isCustomProject = selectedProject?.compare_type === "custom";
  const isYTSelected = selectedProject?.enable_all_time_date_filters === false;

  const getCustomFilters = () =>
    getDefaultFiltersConfig(t, me)
      .filter(({ name }) => name === "data_range_option")
      .map((filter: IDynamicFilter<IFilterRangeProps>) => ({
        ...filter,
        tooltip: isCustomProject
          ? t("components:comparesPanel:header:customProjectDateRangeTooltip")
          : filter.tooltip,
        props: {
          ...filter.props,
          placeholder: t("generic:choose"),
          isYTSelected,
          // Antd v3+ does not trigger onValuesChange on `form.setFieldValue()` no more.
          // Suggestion: push for regular form handling (with Apply btn), rather than this on fly handling
          onChangeDataRangeOption: (value: string) => {
            if (value === "custom") return;

            if (value === "") return onFilter({});

            return onFilter(value ? { data_range_option: value } : {});
          },
          onCalendarChange: (value: RangeValue<Dayjs>) => {
            // Trigger if both dates are set
            // TODO: Remove once new DatePicker component is implemented
            if (value?.length === 2 && value[1] !== null) {
              return onFilter({
                data_range_option: "custom",
                start_time: value[0]?.toISOString(),
                end_time: value[1].toISOString(),
              });
            }
          },
          onClear: () => onFilter({}),
        },
      }));

  const removeCompareProject = useCallback(
    (id: string) => dispatch(deleteCompareProject(id)),
    [dispatch],
  );

  const onRemoveCompareProjectClick = (event: MouseEvent, project: ICompareProject) => {
    event.stopPropagation();
    setProjectToRemove(project);
  };

  const onConfirmRemoveProject = () => {
    if (projectToRemove?.id) {
      removeCompareProject(projectToRemove.id);
      setProjectToRemove(undefined);
    }
  };

  return (
    <div className={s.bbCompareHeader} data-cy="compare-panel-header">
      <div className={s.bbCompareLeft} data-cy="compare-panel-header-left">
        <div className={s.bbHeaderSelectField}>
          <label className={s.bbProjectsSelectLabel} data-cy="compare-panel-project-select-label">
            {t("components:comparesPanel:header:comparisonProject")}
          </label>

          <SelectOld
            noBorder
            showArrow
            allowClear
            options={[]}
            value={selectedProjectId}
            onChange={onProjectChange}
            className={s.bbProjectsSelect}
            dropdownMatchSelectWidth={false}
            placeholder={t("generic:choose")}
            data-cy="compare-panel-projet-select"
            data-stonly-target={"intelligence-compare__comparison-project-dropdown"}
          >
            {compareProjectOptions.map(
              project =>
                project.id && (
                  <AntSelect.Option
                    key={project.id}
                    value={project.id}
                    data-cy="compare-panel-projet-option"
                    className={s.bbProjectsSelectOption}
                  >
                    <span>{project.name}</span>
                    <div>
                      <span onClick={event => onEditProjectClick(event, project)}>
                        <EditIcon />
                      </span>
                      <span onClick={event => onRemoveCompareProjectClick(event, project)}>
                        <RemoveIcon />
                      </span>
                    </div>
                  </AntSelect.Option>
                ),
            )}
          </SelectOld>
        </div>

        <div className={s.bbHeaderSelectField}>
          <Filters
            onFilter={() => {}} // eslint-disable-line
            initialFilterData={filterData}
            customFilters={getCustomFilters()}
            clientPlatformTypes={clientData?.platform_types || []}
          />
        </div>
      </div>
      <div className={s.bbCompareRight} data-cy="compare-panel-header-right">
        <Button
          _type="primary"
          onClick={onNewProjectCreate}
          className={s.bbCompareRightCreateBtn}
          data-cy="compare-panel-header-create-btn"
          data-stonly-target="intelligence-compare__new-project-button"
        >
          <span className={s.bbCompareRightCreateBtnIcon}>
            <PlusIcon />
          </span>
          {t("components:comparesPanel:header:newProject")}
        </Button>
      </div>

      <Modal
        onOk={onConfirmRemoveProject}
        open={!!projectToRemove}
        onCancel={() => setProjectToRemove(undefined)}
        title={t("components:comparesPanel:removeProjectModal:title")}
      >
        <Typography.Paragraph>
          {t("components:comparesPanel:removeProjectModal:text", {
            deleteName: projectToRemove?.name || "",
          })}
        </Typography.Paragraph>
      </Modal>
    </div>
  );
};

export default CompareHeader;
