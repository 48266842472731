import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";

import { Card } from "@bbdevcrew/bb_ui_kit_fe";
import { ManagedSummaryCard } from "../../summary/SummaryCard";

import reportStyles from "../PDFReport.module.less";
import s from "./ActionSummary.module.less";

import { IActionSummaryProps } from "./ActionSummary.types";

export const ActionSummary: React.FC<IActionSummaryProps> = ({ data, isFetching, className }) => {
  const { t } = useTranslation();

  return (
    <div className={reportStyles.bbPDFReportSection}>
      <h2 className={classNames(reportStyles.bbPDFReportSectionTitle, className)}>
        {t("components:managed:summary:title")}
      </h2>
      <div className={s.bbActionSummary}>
        {!isFetching &&
          data?.map((item, index) => (
            <Card key={index}>
              <ManagedSummaryCard {...item} isLoading={false} />
            </Card>
          ))}
      </div>
    </div>
  );
};
