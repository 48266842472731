import { i18nextInstance } from "../../../App";

import { ISimpleTableColumn } from "@bbdevcrew/bb_ui_kit_fe";
import { IInudstryFilterItem } from "@store/tiktokHashtags/types";

export const TABLE_PAGE_SIZE = 25;
export const MAX_SHOW_FLAG_COUNT = 5;

export const getTableColumns = (): ISimpleTableColumn[] => {
  return [
    {
      name: i18nextInstance.t("components:listen:trendingHashtags:table:columns:rank"),
      id_name: "rank",
      colSpan: 2,
    },
    {
      name: i18nextInstance.t("components:listen:trendingHashtags:table:columns:hashtag"),
      id_name: "name",
      colSpan: 6,
      isSortable: false,
    },
    {
      name: i18nextInstance.t("components:listen:trendingHashtags:table:columns:trendingPosts"),
      id_name: "trendingPosts",
      colSpan: 8,
      isSortable: false,
    },
    {
      name: i18nextInstance.t("components:listen:trendingHashtags:table:columns:posts"),
      id_name: "numberOfPosts",
      colSpan: 2,
    },
    {
      name: i18nextInstance.t("components:listen:trendingHashtags:table:columns:views"),
      id_name: "numberOfViews",
      colSpan: 2,
    },
    {
      name: i18nextInstance.t("components:listen:trendingHashtags:table:columns:trending_in"),
      id_name: "trendingCountries",
      colSpan: 4,
      isSortable: false,
    },
  ];
};

export const getIndustryOptions = (options: IInudstryFilterItem[]) => {
  return options.map(item => ({
    id: item.id,
    label: item.emoji ? `${item.emoji} ${item.label}` : item.label,
    options: item.subIndustries?.map(subIndustry => ({
      id: subIndustry.id,
      label: subIndustry.label,
    })),
  }));
};

const units: { [key: string]: number } = {
  k: 1e3,
  m: 1e6,
  bi: 1e9,
};

export const parseValue = (value: string | number) => {
  if (typeof value === "number") return value;

  const match = value.match(/^(\d+(?:\.\d+)?)([kKmMBi]+)?$/);
  if (!match) return parseFloat(value);

  const [, number, unit] = match;
  return parseFloat(number) * (units[unit?.toLowerCase()] || 1);
};
