import React from "react";

import { Tooltip, InformationIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./FilterLabel.module.less";

interface IFilterLabelProps {
  label: string;
  tooltip?: string;
}

const FilterLabel = ({ label, tooltip }: IFilterLabelProps) => {
  if (!label.length) return null;

  return (
    <div className={s.bbAppFiltersLabelWrapper}>
      <span className={s.bbAppFiltersLabel}>{label}</span>
      {tooltip && (
        <Tooltip title={tooltip}>
          <span className={s.bbAppFiltersLabelHelp}>
            <InformationIcon />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default FilterLabel;
