import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { catchError, switchMap, filter, map } from "rxjs/operators";

import {
  getWorkflowsSuccessAction,
  getWorkflowsFailureAction,
  createWorkflowSuccessAction,
  createWorkflowFailureAction,
  editWorkflowSuccessAction,
  editWorkflowFailureAction,
  deleteWorkflowSuccessAction,
  deleteWorkflowFailureAction,
  enableWorkflowSuccessAction,
  enableWorkflowFailureAction,
  disableWorkflowSuccessAction,
  disableWorkflowFailureAction,
  getWorkflowsSavedRepliesSuccessAction,
  getWorkflowsSavedRepliesFailureAction,
  getMatchingCommentsFailureAction,
  getMatchingCommentsSuccessAction,
} from "./actions";
import {
  GET_WORKFLOWS,
  CREATE_WORKFLOW,
  EDIT_WORKFLOW,
  DELETE_WORKFLOW,
  ENABLE_WORKFLOW,
  DISABLE_WORKFLOW,
  GET_WORKFLOWS_SAVED_REPLIES,
  GET_MATCHING_COMMENTS,
} from "./actionTypes";

import { RootState } from "../index";
import { commentsSearch, workflows } from "@utils/paths";

import {
  WorkflowsActions,
  IGetWorkflowsResponse,
  IWorkflowItem,
  IWorkflowItemPayload,
} from "./types";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";
import { ISavedReply } from "@store/replies/types";
import { IComment } from "@store/comments/types";

function workflowItemMapper(item: IWorkflowItemPayload): IWorkflowItem {
  if (item.category === "sentiment")
    return {
      ...item,
      trigger: { keyword_query: item.trigger?.filters?.keyword_query || [] },
      action: item.sentiment,
    } as IWorkflowItem;
  if (item.category === "tag") {
    return {
      ...item,
      trigger: {
        keyword_query: item.trigger?.filters?.keyword_query,
        time_frame: item.trigger?.filters?.time_frame,
      },
      action: item.tags,
    } as IWorkflowItem;
  }
  if (item.category === "archive" || item.category === "hide")
    return {
      ...item,
      trigger: { keyword_query: item.trigger?.filters?.keyword_query || [] },
    } as IWorkflowItem;
  return item as unknown as IWorkflowItem;
}

export const getWorkflowsEpic = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_WORKFLOWS)),
    switchMap(() => {
      return ajax
        .get<IGetWorkflowsResponse>(
          workflows,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => ({ ...data, items: data.items.map(workflowItemMapper) })),
          map(data => getWorkflowsSuccessAction(data)),
          catchError(e => handleError(e, getWorkflowsFailureAction)),
        );
    }),
  );

export const createWorkflowEpic = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(CREATE_WORKFLOW)),
    switchMap(a => {
      return ajax
        .post(
          workflows,
          {
            ...a.payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => createWorkflowSuccessAction()),
          catchError(e => handleError(e, createWorkflowFailureAction)),
        );
    }),
  );

export const editWorkflowEpic = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(EDIT_WORKFLOW)),
    switchMap(a => {
      const { id, ...payloadExcludingId } = a.payload;
      return ajax
        .put(
          `${workflows}/${id}`,
          payloadExcludingId,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => editWorkflowSuccessAction()),
          catchError(e => handleError(e, editWorkflowFailureAction)),
        );
    }),
  );

export const deleteWorkflowEpic = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_WORKFLOW)),
    switchMap(a => {
      return ajax
        .delete<{ id: string }>(
          `${workflows}/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deleteWorkflowSuccessAction()),
          catchError(e => handleError(e, deleteWorkflowFailureAction)),
        );
    }),
  );

export const enableWorkflowEpic = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(ENABLE_WORKFLOW)),
    switchMap(a => {
      return ajax
        .patch<{ id: string }>(
          `${workflows}/${a.payload}/enable`,
          {
            id: a.payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => enableWorkflowSuccessAction()),
          catchError(e => handleError(e, () => enableWorkflowFailureAction(a.payload))),
        );
    }),
  );

export const disableWorkflowEpic = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DISABLE_WORKFLOW)),
    switchMap(a => {
      return ajax
        .patch<{ id: string }>(
          `${workflows}/${a.payload}/disable`,
          {
            id: a.payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => disableWorkflowSuccessAction()),
          catchError(e => handleError(e, () => disableWorkflowFailureAction(a.payload))),
        );
    }),
  );

export const getWorkflowsSavedReplies = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_WORKFLOWS_SAVED_REPLIES)),
    switchMap(a => {
      return ajax
        .post<ISavedReply[]>(
          `${workflows}/templates-list`,
          {
            query: a.payload?.query,
            preset_query: a.payload?.preset_query,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getWorkflowsSavedRepliesSuccessAction(data)),
          catchError(e => handleError(e, getWorkflowsSavedRepliesFailureAction)),
        );
    }),
  );

export const getMatchingCommentsEpic = (
  action$: Observable<WorkflowsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_MATCHING_COMMENTS)),
    switchMap(a => {
      return ajax
        .post<{ items: IComment[]; total_count: number }>(
          commentsSearch,
          {
            filters: a.payload,
            cursor: "",
            limit: 20,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getMatchingCommentsSuccessAction(data.items, data.total_count)),
          catchError(e => handleError(e, getMatchingCommentsFailureAction)),
        );
    }),
  );
