export const TIKTOK_GET_TRENDING_HASHTAGS = "TIKTOK/GET_TRENDING_HASHTAGS";
export const TIKTOK_GET_TRENDING_HASHTAGS_SUCCESS = "TIKTOK/GET_TRENDING_HASHTAGS_SUCCESS";
export const TIKTOK_GET_TRENDING_HASHTAGS_FAILURE = "TIKTOK/GET_TRENDING_HASHTAGS_FAILURE";

export const TIKTOK_GET_TRENDING_HASHTAGS_FILTERS = "TIKTOK/GET_TRENDING_HASHTAGS_FILTERS";
export const TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_SUCCESS =
  "TIKTOK/GET_TRENDING_HASHTAGS_FILTERS_SUCCESS";
export const TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_FAILURE =
  "TIKTOK/GET_TRENDING_HASHTAGS_FILTERS_FAILURE";

export const TIKTOK_GET_TRENDING_HASHTAG_VIDEOS = "TIKTOK/GET_TRENDING_HASHTAG_VIDEOS";
export const TIKTOK_GET_TRENDING_HASHTAG_VIDEOS_SUCCESS =
  "TIKTOK/GET_TRENDING_HASHTAG_VIDEOS_SUCCESS";
export const TIKTOK_GET_TRENDING_HASHTAG_VIDEOS_FAILURE =
  "TIKTOK/GET_TRENDING_HASHTAG_VIDEOS_FAILURE";
