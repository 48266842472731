import { formatDateEnUs } from "@components/insights/agentPerformance/AgentPerformance.helpers";
import { calculateDateRange } from "@components/insights/overview/helpers";
import { IFilters } from "@store/filters/types";

import { ReportSectionType } from "@components/_common/DownloadReportModal/DownloadReportModal.types"; // eslint-disable-line max-len
import { OverviewComponentType } from "@store/dashboard/types";
import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";

export const REPORT_READY_SELECTOR = "content-loaded";

export const getDateRange = (filters?: IFilters) => {
  let from, to;

  if (filters?.start_time && filters?.end_time) {
    from = formatDateEnUs(filters.start_time);
    to = formatDateEnUs(filters.end_time);
  } else if (filters?.data_range_option) {
    const { fromDate, toDate } = calculateDateRange(filters?.data_range_option);
    from = fromDate;
    to = toDate;
  }

  if (!from || !to) {
    return "";
  }

  return `${from} - ${to}`;
};

export const dashboards: Record<ReportSectionType, OverviewComponentType[]> = {
  sentiment_preview: ["explorer_overview_sentiment", "explorer_overview_platform"],
  action_summary: ["action_summary_stats"],
  message_types: [
    "explorer_overview_message_types",
    "explorer_overview_top_languages",
    "explorer_overview_top_influencers",
  ],
  bb_protect: ["hidden_comments"],
  bb_engage: ["explorer_overview_care"],
  sentiment_extended: ["sentiment_extended"],
  topics: [
    "explorer_overview_top_emojis",
    "explorer_overview_top_keywords",
    "explorer_overview_top_keyword_phrases",
    "top_tags",
  ],
};

export const usePDFParams = () => {
  const [searchParams] = useSearchParams();

  const name = searchParams.get("name") || "";
  const mode = searchParams.get("mode");
  const id = searchParams.get("id");
  const savedFilterId = searchParams.get("saved_filter");
  const sections: ReportSectionType[] = useMemo(
    () =>
      (searchParams.get("sections") || "")
        .split(",")
        .map(section => section.toLowerCase().trim() as ReportSectionType) || [],
    [searchParams],
  );

  return {
    name,
    id,
    savedFilterId,
    mode,
    sections,
  };
};

export const getSectionStates = (sections: string[]) => ({
  isOverview: sections.includes("message_types"),
  hasSentimentPreview: sections.includes("sentiment_preview"),
  hasOverviewOrSentiment: ["sentiment_extended", "sentiment_preview"].some(s =>
    sections.includes(s),
  ),
  hasSentimentOrTopics: ["topics", "sentiment_extended"].some(s => sections.includes(s)),
});
