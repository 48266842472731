import React, { FC } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import SectionDivider from "@components/insights/overview/OverviewSectionDivider";
import { Card } from "@bbdevcrew/bb_ui_kit_fe";
import PieChartWidget from "@components/insights/_common/pieChartWidget/PieChartWidget";
import { ProfilesTableWrapper } from "./profilesTable/ProfilesTableWrapper";

import s from "./Profiles.module.less";

import { ProfilesSectionProps } from "./Profiles.types";
import { PROFILE_SECTION_ICONS } from "./Profiles.helpers";

export const ProfilesSection: FC<ProfilesSectionProps> = ({
  loading,
  chartData,
  tableData,
  type,
}) => {
  const { t } = useTranslation();

  const hasChartTitle = type === "impressions";

  const sectionIcon = PROFILE_SECTION_ICONS[type];

  const chartTotal = chartData.find(item => item.rawName === "total");
  const chartValue = chartData.filter(item => item.rawName !== "total" && !!item.value);

  return (
    <div className={s.bbProfilesContent}>
      <SectionDivider
        icon={sectionIcon}
        title={t(`components:listen:profiles:${type}:title`)}
        description={t(`components:listen:profiles:${type}:description`)}
      />
      {hasChartTitle && (
        <span className={s.bbProfilesImpressionsTitle}>
          {t(`components:listen:profiles:impressions:chart:title`)}
        </span>
      )}
      <div className={s.bbProfilesWidgets}>
        <Card
          headerTitle={
            !hasChartTitle ? t(`components:listen:profiles:${type}:chart:title`) : undefined
          }
          className={classNames(s.bbProfilesChartWrapper)}
        >
          <div className={s.bbProfilesChart}>
            <PieChartWidget
              loading={loading}
              position="center"
              hasLabel={false}
              defaultValue={chartTotal}
              chartData={chartValue}
              emptyLabel={t("generic:emptyTable")}
            />
          </div>
        </Card>
        {type === "followers" && (
          <ProfilesTableWrapper
            type="followers"
            data={tableData.filter(item => item.id !== "total")}
            loading={loading}
            total={tableData.find(item => item.id === "total")}
          />
        )}
        {type === "impressions" && (
          <ProfilesTableWrapper
            type="impressions-platform"
            data={tableData.filter(item => item.id !== "total")}
            loading={loading}
            total={tableData.find(item => item.id === "total")}
          />
        )}

        {type === "engagements" && (
          <ProfilesTableWrapper
            type="engagements"
            data={tableData.filter(item => item.id !== "total")}
            loading={loading}
            total={tableData.find(item => item.id === "total")}
          />
        )}
      </div>
    </div>
  );
};
