import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { SimpleDropdown, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./TikTokPreview.module.less";

import preloadImage from "@utils/preloadImage";

import { ITikTokPreviewListProps, ITikTokPreviewProps } from "./TikTokPreview.types";

import { ChevronDownIcon, ChevronUpIcon } from "@bbdevcrew/bb_ui_kit_fe";

const TikTokPreview: React.FC<ITikTokPreviewProps> = ({ url, thumbnail }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setLoaded(false);
    if (!!thumbnail) {
      preloadImage(thumbnail).then(() => setLoaded(true));
    }
  }, [url, thumbnail]);

  return (
    <div className={s.bbTiktokPreview}>
      {loaded && (
        <SimpleDropdown
          open={open}
          setOpen={setOpen}
          trigger={<img src={thumbnail} />}
          triggerMode="hover"
          placement="top-start"
          hoverTimeout={0}
        >
          <Tooltip title={t("components:listen:trendingHashtags:openOnTikTok")}>
            <div className={s.bbTiktokPreviewItem}>
              <img src={thumbnail} onClick={() => window.open(url, "_blank")} />
            </div>
          </Tooltip>
        </SimpleDropdown>
      )}
    </div>
  );
};

export const TikTokPreviewList: React.FC<ITikTokPreviewListProps> = ({ videos }) => {
  const { t } = useTranslation();

  const [collapsed, setCollapsed] = useState(true);

  const displayedVideos = collapsed ? videos.slice(0, 4) : videos;

  return (
    <div
      className={classNames(s.bbTiktokPreviewList, {
        [s.bbTiktokPreviewListCollapsed]: collapsed,
      })}
    >
      {displayedVideos.map(video => (
        <TikTokPreview
          key={video.share_url}
          url={video.share_url}
          thumbnail={video.thumbnail_url}
        />
      ))}
      <button onClick={() => setCollapsed(!collapsed)} className={s.bbTiktokPreviewButton}>
        {collapsed ? (
          <>
            {t("generic:more")}
            <br />
            <ChevronDownIcon />
          </>
        ) : (
          <>
            {t("generic:less")}
            <br />
            <ChevronUpIcon />
          </>
        )}
      </button>
    </div>
  );
};
