import { action } from "typesafe-actions";
import {
  TIKTOK_GET_TRENDING_HASHTAGS,
  TIKTOK_GET_TRENDING_HASHTAGS_SUCCESS,
  TIKTOK_GET_TRENDING_HASHTAGS_FAILURE,
  TIKTOK_GET_TRENDING_HASHTAGS_FILTERS,
  TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_FAILURE,
  TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_SUCCESS,
  TIKTOK_GET_TRENDING_HASHTAG_VIDEOS_FAILURE,
  TIKTOK_GET_TRENDING_HASHTAG_VIDEOS_SUCCESS,
  TIKTOK_GET_TRENDING_HASHTAG_VIDEOS,
} from "./actionTypes";
import {
  IFilterValue,
  ITrendingHashtagsItem,
  ITrendingHashtagsFilters,
  ITrendingHashtagsVideoPayload,
} from "./types";

export const getTrendingHashtagsAction = (payload: IFilterValue) =>
  action(TIKTOK_GET_TRENDING_HASHTAGS, payload);
export const getTrendingHashtagsSuccessAction = (
  data: ITrendingHashtagsItem[],
  filters: IFilterValue,
) => action(TIKTOK_GET_TRENDING_HASHTAGS_SUCCESS, { data, filters });
export const getTrendingHashtagsFailureAction = () => action(TIKTOK_GET_TRENDING_HASHTAGS_FAILURE);

export const getTrendingHashtagsFiltersAction = () => action(TIKTOK_GET_TRENDING_HASHTAGS_FILTERS);
export const getTrendingHashtagsFiltersSuccessAction = (data: ITrendingHashtagsFilters) =>
  action(TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_SUCCESS, data);
export const getTrendingHashtagsFiltersFailureAction = () =>
  action(TIKTOK_GET_TRENDING_HASHTAGS_FILTERS_FAILURE);

export const getTrendingHashtagVideosAction = (hashtagIds: string[], filters: IFilterValue) =>
  action(TIKTOK_GET_TRENDING_HASHTAG_VIDEOS, { hashtagIds, filters });
export const getTrendingHashtagVideosSuccessAction = (data: ITrendingHashtagsVideoPayload[]) =>
  action(TIKTOK_GET_TRENDING_HASHTAG_VIDEOS_SUCCESS, data);
export const getTrendingHashtagVideosFailureAction = () =>
  action(TIKTOK_GET_TRENDING_HASHTAG_VIDEOS_FAILURE);
